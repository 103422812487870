/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import SEO from '../SEO/SEO';

import STAFF_PICK_QUERY from '../apolloMenu/queries/staff-picks.graphql';
import PSEUDO_QUERY from '../apolloMenu/queries/pseudoMenu.graphql';
import FeaturedRow from './featuredRow';

const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  margin-top: 50px;
  p {
    width: 45%;
    text-align: center;
    font-size: 13px;
    min-width: 300px;
    margin-top: 20px;
    padding-top: 0px;
    font-family: 'Oswald';
  }

  p a {
    text-decoration: underline;
    color: var(--darkgreen);
  }
  h1 {
    text-align: center;
    color: var(--lightgreen);
    margin: 0;
  }
  h1 i {
    color: var(--darkgreen);
  }

  .menuLinkWrap {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Oswald';
    button {
      width: 100%;
      max-width: 330px;
      background: transparent;
      border: 1px solid var(--lightgreen);
      width: 310px;
      height: 56px;
      color: var(--lightgreen);
      &:hover {
        cursor: pointer;
        background: var(--lightgreen);
        color: white;
      }
    }
  }
`;

export default function FeaturedMenuHub() {
  const { currentRetailer } = useContext(CheckoutContext);
  return (
    <>
      <SEO title="Curated Menu - HOC Cananda" description="Add description" />
      <PageTitle>
        <h1>
          House of Cannabis <i>Curated Menu</i>
        </h1>
        <p>
          Take a look at our featured products, specials, and other curated
          collections.
        </p>

        <div className="menuLinkWrap">
          <Link to="/menu">
            <button className="main_button">
              Check out our <u>FULL MENU</u>
            </button>
          </Link>
        </div>
      </PageTitle>
      <FeaturedRow
        title="staff picks"
        QUERY={STAFF_PICK_QUERY}
        variables={{
          retailerId: currentRetailer,
        }}
      />
      <FeaturedRow
        title="Testing Swiper"
        QUERY={PSEUDO_QUERY}
        variables={{
          retailerId: currentRetailer,
        }}
      />
    </>
  );
}
