/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ProductCard from '../apolloMenu/productCard/ProductCard';
import Preloader from '../Preloader';

SwiperCore.use([Pagination, Navigation]);

const Wrapper = styled.div`
  margin: 50px 20px;
  .swiper-button-next {
    height: 100%;
    top: 0;
    right: 0;
    width: 225px;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: var(--darkgreen);
  }
  .swiper-button-prev {
    height: 120%;
    top: 0;
    left: 0;
    width: 225px;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    color: var(--darkgreen);
  }
  .swiper-button-disabled {
    display: none;
  }
  .swiper-product-row {
    height: 100%;
    padding: 40px 10px;
    padding-bottom: 70px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -5px;
    margin-bottom: 20px;
  }
  .swiper-pagination {
    margin-bottom: -10px;
  }
  .swiper-pagination-bullet-active {
    color: var(--darkgreen);
  }

  .swiper-pagination-bullet {
    color: var(--darkgreen);
    background-color: var(--darkgreen);
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-top: 0px;
    margin-left: 0;
    h2 {
      margin: 0;
    }
    .swiper-pagination-bullet {
      display: none;
    }
    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      display: none;
    }

    .swiperCard {
      border: 1px solid black;
    }
  }
`;
const AnimationLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default function FeaturedRow({ title, QUERY, variables }) {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });
  return (
    <Wrapper>
      <h2>{title}</h2>
      <Swiper
        className="swiper-product-row"
        spaceBetween={10}
        slidesPerView={1.15}
        slidesPerGroup={1}
        pagination={{
          clickable: true,
        }}
        navigation
        breakpoints={{
          640: {
            slidesPerView: 2.5,
            spaceBetween: 15,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 15,
          },
        }}
      >
        {data && !loading ? (
          data.menu.products.map((product, i) => (
            <SwiperSlide key={i} virtualIndex={i} className="swiperCard">
              <ProductCard product={product} mobileRowMode />
            </SwiperSlide>
          ))
        ) : loading ? (
          <AnimationLoader>
            <Preloader />
          </AnimationLoader>
        ) : (
          <>error...FeaturedRow</>
        )}
      </Swiper>
    </Wrapper>
  );
}
