import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const Preloader = () => (
  <Player
    autoplay
    loop
    src="https://assets6.lottiefiles.com/packages/lf20_sqsa6hpt.json"
    style={{ width: 200, height: 200 }}
  />
);

export default Preloader;
