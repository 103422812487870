import React from 'react';
import FeaturedMenuHub from '../components/menuCarousel/featuredMenuHub';

export default function FeaturedMenu() {
  return (
    <>
      <FeaturedMenuHub />
    </>
  );
}
